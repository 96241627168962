import {
    Checkbox,
    Collapse,
    TableCell,
    TableRow,
  } from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { visibilityStates } from "../../../app/config/visibilityStates";
import { fetchLogs, nextPage, previousPage, resetFilter, setFilter } from "../../../app/slices/logsSlice";
import ComplainLogsList from "../../forms/complain/complainLogsList";
import {useEffect} from "react";


function ComplainsTableRow({ open, complain, columns, onRowClick, checked, onCheck, logs, filter }) {
    const { t: t_button } = useTranslation('Buttons');
    const { t: t_common } = useTranslation('Common');
    const { t: t_link} = useTranslation('Pages');
    const { t: t_status } = useTranslation('ComplainsScreen', { keyPrefix: 'TableFilter.status' });
    const { t: t_visibility } = useTranslation('ComplainsScreen', { keyPrefix: 'TableFilter.visibility' });

    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const status = () => {
        if (complain.isClosedAutomatically) return `closed_automatically_${complain.isResolved ? 'resolved' : 'unresolved'}`;
        if (complain.isDraft) return 'draft';
        if (complain.status === 1) return complain.isResolved ? 'closed_resolved' : 'closed_unresolved';

        return 'open';
    }

    const visibilityText = _.find(
        visibilityStates,
        (e) => e.value === complain.isPublic
    )?.text;

    const refreshLogs = async () => {
        await dispatch(fetchLogs({ complaintId: complain.id }))
    }

    const handlePreviousPage = () => {
        dispatch(previousPage());
        refreshLogs();
    }

    const handleNextPage = () => {
        dispatch(nextPage());
        refreshLogs();
    }

    const handleRequestSort = (event, property) => {
        const direction =
            filter.orderBy === property &&
            filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        dispatch(setFilter({ field: "orderBy", value: property }));
        dispatch(setFilter({ field: "orderDirection", value: direction }));

        refreshLogs();
    }

    const checkIfCellDisplays = (cellId) => !!_.find(columns, (column) => column.id === cellId);
    const checkIfEmailUndeliveredLogExists = () => {
        let logs = _.orderBy(complain.reportLogs, ['createdAt'], ['desc']);

        if (!logs.length) return false;

        if (logs[0].type === 'UndeliveredEmail') return true;

        return false;
    }

    const onCellClick = async () => {
        onRowClick(complain.id);
        dispatch(resetFilter());
        await refreshLogs();
    }

    return (
        <>
            <TableRow
                className={`row ${open ? " active" : ""}`}
            >
                <TableCell padding="checkbox">
                    <Checkbox color="primary" checked={checked} onClick={onCheck} />
                </TableCell>
                {checkIfCellDisplays('createdAt') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell --first"
                        style={{minWidth: '200px'}}
                        onClick={onCellClick}
                    >
                        <span className="value">
                            {format(
                                new Date(complain.firstCreatedAt),
                                "yyyy-MM-dd 'godz:' HH:mm"
                            )}
                        </span>
                    </TableCell>
                }
                {checkIfCellDisplays('companyName') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{complain.companyName}</span>
                    </TableCell>
                }
                {checkIfCellDisplays('title') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{complain.title}</span>
                    </TableCell>
                }
                {checkIfCellDisplays('fullName') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{complain.consumerFullName}</span>
                    </TableCell>
                }
                {checkIfCellDisplays('sector') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{complain.sector?.name ?? '-'}</span>
                    </TableCell>
                }
                {checkIfCellDisplays('isPublic') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{t_visibility(visibilityText)}</span>
                    </TableCell>
                }
                {checkIfCellDisplays('type') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{complain.type?.name ?? '-'}</span>
                    </TableCell>
                }
                {checkIfCellDisplays('number') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{complain.number}</span>
                    </TableCell>
                }
                {checkIfCellDisplays('duplicatedNumber') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{complain.duplicatedNumber}</span>
                    </TableCell>
                }
                {checkIfCellDisplays('parentNumber') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{complain.parentNumber}</span>
                    </TableCell>
                }
                {checkIfCellDisplays('haveComments') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        <span className="value">{t_common(complain.hasConsumerComment ? 'yes' : 'no')}</span>
                    </TableCell>
                }
                <TableCell
                    padding="none"
                    className="fk-table-cell"
                    onClick={onCellClick}>
                    <span className="value">{t_common(complain.hasCompanyComment ? 'yes' : 'no')}</span>
                </TableCell>
                {checkIfCellDisplays('status') &&
                    <TableCell
                        padding="none"
                        className="fk-table-cell"
                        onClick={onCellClick}
                    >
                        {!complain.isDelivered
                            ? <span className="value error">{t_status('undeliveredEmailStatusText')}</span>
                            : <span className="value">{t_status(status())}</span>
                        }
                    </TableCell>
                }
                <TableCell padding="none" className="fk-table-cell crud --last">
                    <span className="value">
                        <button
                            className="show-details-button"
                            onClick={() => navigate(t_link('complainLink') + complain.id)}
                        >
                            {t_button('show')}
                        </button>
                    </span>
                </TableCell>
            </TableRow>
            {open &&
                <TableRow className="row collapsed-data">
                    <TableCell padding="checkbox" />
                    <TableCell
                        className="fk-table-cell --first"
                        colSpan={columns.length + 1}
                    >
                        <Collapse in={open} timeout="auto" className="collapse">
                            <ComplainLogsList
                                onRequestSort={handleRequestSort}
                                onPreviousPage={handlePreviousPage}
                                onNextPage={handleNextPage}
                            />
                        </Collapse>
                    </TableCell>
                </TableRow>
            }

      </>
    );
}

export default connect(null, {})(ComplainsTableRow);