import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ScreenHeader } from '../components/screenHeader';
import {
    fetchComplains,
    getComplain,
    updateComplain,
    deleteComplain,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
    sendAgainToCompany,
    removeComplain,
    closeComplain,
    changeVisibilityIsBlocked,
} from "../app/slices/complainsSlice";
import {
    getConsumer,
} from '../app/slices/consumersSlice';
import {
    getCompany,
} from '../app/slices/companiesSlice';
import {
    fetchLogs,
    nextPage as nextPageLogs,
    previousPage as previousPageLogs,
    resetFilter as resetFilterLogs,
    setFilter as setFilterLogs,
} from "../app/slices/logsSlice";
import { fetchComplainTypes } from '../app/slices/complainTypesSlice';
import { showModal } from '../app/slices/modalSlice';
import { withRouter } from '../wrappers/withRouter';
import { ConsumerInfoForm } from '../components/forms/consumer';
import { DeleteOutline } from '@mui/icons-material';
import { ComplainAdminsComment, ComplainAttachments, ComplainDataForm, ComplainDuplicateList, ComplainHistory, ComplainMessages, ComplainStatusForm } from '../components/forms/complain';
import { CompanyAdditionalInfoForm, CompanyInfoForm } from '../components/forms/company';
import { PrivateIconWithBg, PublicIconWithBg } from '../assets/icons';
import { format } from 'date-fns';
import { withTranslation } from 'react-i18next';
import ComplainLogsList from '../components/forms/complain/complainLogsList';
import {APP_URLS, STORAGE} from "../app/config";
import axios from "axios";

const ComplainEditScreen = (props) => {
    const [id, setId] = useState(props.params.id);
    const [isStatusChangeRequested, setIsStatusChangeRequested] = useState(false);
    const [model, setModel] = useState();

    const fetchComplaint = (id) => {
        const token = JSON.parse(localStorage.getItem(STORAGE.ACCESS_TOKEN_KEY))?.token;
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token,
        }

        axios.get(APP_URLS.COMPLAIN_GET.replace('{id}', id),
            { headers }).then(response => {
                setModel(response.data);
        })
    }

    useEffect(() => {
        fetchComplaint(id);
        
        return () => {
            setModel(null);
        }
    }, [id]);
    
    useEffect(() => {
        props.resetFilter();
        refresh();
    }, [model]);

    const refresh = async () => {
        await props.getComplain(props.params.id);
        if (model?.company?.id) {
            await props.getCompany(model.company.id);
        }

        await props.fetchComplainTypes(props.sector);

        if (model?.reporter?.id) {
            await props.getConsumer(model.reporter.id);
        }

        await refreshComplains();
        await refreshLogs();
    }

    useEffect(() => {
        if (props.params.id !== id) {
            setId(props.params.id);
            refresh().then(() => {
                window.scrollTo(0, 0);
            });
        }
    }, [props.params.id, id]);
    
    const refreshComplains = async () => {
        await props.fetchComplains({ parentId: props.params.id });
    }

    const refreshLogs = async () => {
        await props.fetchLogs({ complaintId: props.params.id });
    }

    const handleFieldSave = async (field, value, isStatus) => {
        try {
            await props.updateComplain({
                id: props.params.id,
                fieldName: field,
                value: value + "",
                isStatusEdit: !!isStatus,
            });

            if (isStatus) {
                setIsStatusChangeRequested(false);
            }
        } catch (e) {
            console.log('e :>> ', e);
        }

        await refresh();
    }

    const handlePreviousPage = () => {
        props.previousPage();
        refreshComplains();
    }

    const handleNextPage = () => {
        props.nextPage();
        refreshComplains();
    }

    const handleRequestSort = (event, property) => {
        const direction =
            props.filter.orderBy === property &&
            props.filter.orderDirection === "asc"
                ? "desc"
                : "asc";
        props.setFilter({ field: "orderBy", value: property });
        props.setFilter({ field: "orderDirection", value: direction });

        refreshComplains();
    }

    const handlePreviousPageLogs = () => {
        props.previousPageLogs();
        refreshLogs();
    }

    const handleNextPageLogs = () => {
        props.nextPageLogs();
        refreshLogs();
    }

    const handleRequestSortLogs = (event, property) => {
        const direction =
            props.logsFilter.orderBy === property &&
            props.logsFilter.orderDirection === "asc"
                ? "desc"
                : "asc";
        props.setFilterLogs({ field: "orderBy", value: property });
        props.setFilterLogs({ field: "orderDirection", value: direction });

        refreshLogs();
    }

    const handleRowCheck = (item, isChecked) => {
        if (!isChecked) {
            props.checkItem(item);
        } else {
            props.uncheckItem(item);
        }
    }

    const handleSelectAll = () => {
        if (props.selectedItems.length === props.complains.length) {
            props.uncheckAllItems();
        } else {
            props.checkAllItems();
        }
    }

    const handleRowClick = (id) => {
        setId(id);
    }

    const handleSendAgainToCompany = async () => {
        let result = await props.sendAgainToCompany(props.params.id);

        if (!result.hasOwnProperty('error')) {
            props.showModal({
                title: 'Wybrana sprawa została wysłana ponownie.',
                mode: 'normal',
            });
        } else {
            props.showModal({
                title: 'Wystąpił błąd podczas wysyłania sprawy ponownie.',
                mode: 'danger',
            });
        }
    }

    const handleChangeVisibilityIsBlocked = async () => {
        await props.changeVisibilityIsBlocked(props.params.id);
        refresh();
    }

    const handleStatusChange = () => {
        setIsStatusChangeRequested(true);
    }

    const handleDelete = () => {
        props.showModal({
            title: 'Usunięcie sprawy',
            message: `Czy na pewno chcesz usunąć sprawę nr ${model.number}?`,
            button: 'Usuń',
            mode: 'normal',
            cancelButton: true,
            fallback: () => {
                props.removeComplain(model.id).then((data) => {
                    if (data.payload?.status === 200) {
                        props.showModal({
                            message: 'Sprawa została usunięta',
                            mode: 'normal',
                            fallback: () => {
                                props.navigate('/sprawy');
                            }
                        });

                    } else {
                        props.showModal({
                            message: 'Wystąpił błąd podczas usuwania sprawy',
                            mode: 'danger',
                        });
                    }
                });
            }
        });
    }

    const handleCloseComplaint = () => {
        props.showModal({
            message: 'Czy problem został rozwiązany?',
            mode: 'normal',
            yesNoButtons: true,
            fallbackYes: () => {
                props.closeComplain({
                    id: model.id,
                    isSuccess: true,
                }).then(async () => {
                    await refresh();
                });
            },
            fallbackNo: () => {
                props.closeComplain({
                    id: model.id,
                    isSuccess: false,
                }).then(async () => {
                    await refresh();
                });
            }
        });
    }
    

    if (!model?.id) return null;

    return (
        <div className="fk-screen">
            <ScreenHeader
                withBackButton
                withSeparator
                title="complainHeader"
                titleReplacement={['#COMPLAIN_NUMBER#', model.number]}
                subtitle={format(
                    new Date(model.firstCreatedAt),
                    "yyyy-MM-dd 'godz:' HH:mm"
                )}
                additionalButtons={
                    <div className="screen-header-buttons">
                        <div className={`fk-change-complain-visibility ${model.isPublic ? '--public' : '--private'}`}>
                            <img src={model.isPublic ? PublicIconWithBg : PrivateIconWithBg} alt="" />
                            {model.isPublic ? 'Publiczna' : 'Prywatna'}
                            <Button className="green" onClick={async () => await handleFieldSave('IsPublic', !model.isPublic)}>[{props.t_buttons('change')}]</Button>
                        </div>
                        <div className="right-side">
                            <div className="button-with-label">
                                <label>{model.changeVisibilityBlocked ? props.t_buttons('visibilityChangeIsBlocked') : props.t_buttons('visibilityChangeIsAvailable')}</label>
                                <Button className="green" onClick={handleChangeVisibilityIsBlocked}>[{props.t_buttons('change')}]</Button>
                            </div>
                            <Button className="green" onClick={handleSendAgainToCompany}>{props.t_buttons('sendAgainToCompany')}</Button>
                            <Button className="green" onClick={handleStatusChange}>{props.t_buttons('changeComplainStatus')}</Button>
                            {!model?.isResolved &&
                                <Button className="green" onClick={handleCloseComplaint}>{props.t_buttons('closeComplain')}</Button>
                            }
                            <Button className="red" onClick={handleDelete}><DeleteOutline /> {props.t_buttons('delete')}</Button>
                        </div>
                    </div>
                }
            />
            <Grid container spacing={4}>
                <Grid item md={6}>
                    <ComplainStatusForm onUpdate={handleFieldSave} isEditRequested={isStatusChangeRequested} />
                    <ComplainDataForm onUpdate={handleFieldSave} />
                    <ComplainAttachments onUpdate={refresh} />
                </Grid>
                <Grid item md={6}>
                    <ConsumerInfoForm />
                    <CompanyInfoForm />
                    <CompanyAdditionalInfoForm />
                </Grid>
            </Grid>
            <ComplainDuplicateList
                parentId={model.id}
                selectedItems={props.selectedItems}
                onRequestSort={handleRequestSort}
                onRowCheck={handleRowCheck}
                onSelectAll={handleSelectAll}
                onPreviousPage={handlePreviousPage}
                onNextPage={handleNextPage}
            />
            <ComplainMessages onUpdate={refresh} messages={model?.reportMessages} />
            <ComplainAdminsComment onUpdate={handleFieldSave} />
            <ComplainHistory />
            <ComplainLogsList
                onDetails
                onRequestSort={handleRequestSortLogs}
                onPreviousPage={handlePreviousPageLogs}
                onNextPage={handleNextPageLogs}
            />
        </div>
    );
}

const mapStateToProps = (state) => ({
    complain: state.complains.complain,
    complains: state.complains.data,
    filter: state.complains.filter,
    selectedItems: state.complains.selectedItems,
    sector: state.companies.company.sectorId,
    logsFilter: state.logs.filter,
});

const actions = {
    fetchComplains,
    getComplain,
    getCompany,
    getConsumer,
    previousPage,
    nextPage,
    resetFilter,
    setFilter,
    checkItem,
    checkAllItems,
    uncheckItem,
    uncheckAllItems,
    updateComplain,
    deleteComplain,
    sendAgainToCompany,
    removeComplain,
    showModal,
    fetchComplainTypes,
    closeComplain,
    changeVisibilityIsBlocked,
    fetchLogs,
    previousPageLogs,
    nextPageLogs,
    resetFilterLogs,
    setFilterLogs,
};

export default withTranslation()(withRouter(connect(mapStateToProps, actions)(ComplainEditScreen)));
