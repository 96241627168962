import React from "react";
import { setComplain } from "../../../app/slices/complainsSlice";
import { Grid, MenuItem, TextField } from "@mui/material";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { dataFieldsArray } from "./config";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import _ from "lodash";

function ComplainDataForm({ onUpdate, model, errors, setComplain, types }) {
    const { t } = useTranslation('ComplainDetailsScreen', { keyPrefix: 'DataForm' });

    const [currentEdit, setCurrentEdit] = React.useState('');

    const checkIfDisabled = (field) => field !== currentEdit;
    const getErrorMessage = (field) => _.find(errors, (error) => error.field === field);
    const handleFieldChange = (field, value) => setComplain({ field, value });

    const handleUpdate = async (name, value) => {
        await onUpdate(name, value);

        setCurrentEdit(null);
    }

    return (
        <div className="fk-form-section">
            <h3 className="title">
                {t('formTitle')}
            </h3>
            <Grid container spacing={2}>
                {dataFieldsArray.map(({name, modelName, required, items, textarea, rows, convert}, index) => {
                    let letsTranslate = true;
                    if (name === 'typeId') {
                        items = [...types];
                        letsTranslate = false;
                    }

                    return (
                        <React.Fragment key={index}>
                            <Grid item xs={3} className="fk-label">
                                <label htmlFor={name}>{`${t(name)}${(required ? '*' : '')}`}</label>
                            </Grid>
                            <Grid item xs={8} className={
                                classNames(
                                    'fk-input-container',
                                    {
                                        'has-error': !!getErrorMessage(name),
                                    }
                                )
                            }>
                                <TextField
                                    fullWidth
                                    select={!!items}
                                    multiline={textarea}
                                    rows={rows}
                                    disabled={checkIfDisabled(name)}
                                    className="fk-input"
                                    placeholder={t(`${name}Placeholder`)}
                                    helperText={getErrorMessage(name)?.error}
                                    value={
                                        model
                                        ? convert ? convert(model[name]) : model[name]
                                        : !!items ? '' : ''
                                    }
                                    onChange={(event) => handleFieldChange(name, event.target.value)}
                                >
                                    {!!items && items.map(({value, text}) => (
                                        <MenuItem key={value} value={value}>
                                            {letsTranslate ? t(text) : text}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            {modelName ?
                                <Grid item xs={1}>
                                    {name !== currentEdit
                                        ? <button
                                            className="fk-edit-button"
                                            onClick={() => setCurrentEdit(name)}
                                        >
                                            <EditIcon />
                                        </button>
                                        : <button
                                            className="fk-edit-button"
                                            onClick={() => handleUpdate(modelName, model[name])}
                                        >
                                            <SaveIcon />
                                        </button>
                                    }
                                </Grid>
                                : <Grid item xs={1} />
                            }
                        </React.Fragment>
                    )
                })}
            </Grid>
        </div>
    );
}

const mapStateToProps = (state) => ({
    model: state.complains.complain,
    errors: state.complains.errors,
    types: state.complainTypes.data,
});

const actions = {
    setComplain,
};

export default connect(mapStateToProps, actions)(ComplainDataForm);