import React, {useState} from 'react';
import { Button, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { mergeCompanies } from '../../app/slices/companiesSlice';
import {FilterMenu} from "../filterMenu";
import {format} from "date-fns";
import {APP_URLS} from "../../app/config";

const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}
function ExportEmailsModal({ show, fallback, onCancel }) {
    const [parent, setParent] = useState(null);
    const { t } = useTranslation('CompaniesScreen', { keyPrefix: 'MergeCompanies' });
    const [filter, setFilter] = useState({from: addDays(new Date(), -30), to: new Date()})
    const {t: tdate} = useTranslation('ComplainsScreen', {keyPrefix: 'TableFilter'});

    if (!show) {
        return null;
    }

    const onCloseButtonClick = async () => {
        onCancel();
    }

    const onCancelButtonClick = () => {
        onCancel();
    }

    const handleFilterChange = (field, value) => {
        setFilter(prev => {
            return {
                ...prev,
                [field]: value,
            }
        })
    }


    const onExportPress = () => {
        const dates = {
            from: filter.from ? filter.from?.toISOString() : '',
            to: filter.to ? filter.to?.toISOString() : '',
        }

        let params = new URLSearchParams(dates);

        const url = `${APP_URLS.COMPANIES_CSV}?${params.toString()}`;
        const link = document.createElement('a');
        link.href = url;
        link.target = "_blank";

        document.body.appendChild(link);
        link.click();

        link.remove();
        window.URL.revokeObjectURL(url);
    }

    return (
        <div className="fk-modal-overlay">
            <div className="fk-modal" style={{maxWidth: '600px'}}>
                <span className="fk-modal-close" onClick={onCancelButtonClick}>
                    &#10005;
                </span>
                <div className="fk-modal-title">
                    Select date range to export
                </div>
                <div className={`fk-modal-body`}>
                    <Grid container spacing={2}>
                        <Grid item md={12} style={{flexDirection: 'row', display: 'flex'}}>
                            <FilterMenu
                                datepicker
                                options={[]}
                                selected={filter.from}
                                buttonText={`${tdate('createdAt.from')} ${filter.from ? format(new Date(filter.from), "dd.MM.yyyy") : ''}`}
                                onChange={(option) => handleFilterChange('from', option)}
                            />
                            <FilterMenu
                                datepicker
                                options={[]}
                                selected={filter.to}
                                buttonText={`${tdate('createdAt.to')} ${filter.to ? format(new Date(filter.to), "dd.MM.yyyy") : ''}`}
                                onChange={(option) => handleFilterChange('to', option)}
                            />
                        </Grid>
                    </Grid>
                </div>
                <div className="fk-modal-buttons">
                    <Button
                        fullWidth
                        className={`fk-modal-button-normal`}
                        onClick={onExportPress}
                    >
                        Export
                    </Button>
                    <Button
                        fullWidth
                        className={`fk-modal-button-danger`}
                        onClick={onCancelButtonClick}
                    >
                        {t('cancel')}
                    </Button>
                </div>
            </div>
        </div>
    );
}


export default ExportEmailsModal;